export const GlobalOfficesData = [
  {
    heading: "DUBAI, UNITED ARAB EMIRATES",
    textb1: `Office 806, 8th Floor, Liberty House, 
Dubai International Financial Centre (DIFC)
T: (+971) 4 287 5413`,
    time: "0.5s",
  },
  {
    heading: "BENGALURU, INDIA",
    textb1: `1st floor, PROM'S Complex, 3h, 7th C
Main Road, 3rd Block Koramangala, 
Bengaluru, Karnataka - 560034`,
    time: "0.1s",
  },
  {
    heading: "MANIPAL, INDIA",
    textb1: `Pragati Business District, 3rd Floor,
Laxmindranagar, Manipal - 576104,
Udupi District `,
    time: "0.3s",
  },
  {
    heading: "MANGALORE, INDIA",
    textb1: `1st floor, Ajanta Business Center, Bejai,
Kapikad Road, Manglore - 575003,
Karnataka `,
    time: "0.3s",
  },
  {
    heading: "RIYADH, KINGDOM OF SAUDI ARABIA",
    textb1: `<b style="display: contents;">Performance Ryada Events Co.</b>
4353 Tahliah St, As Sulimaniyah,
Riyadh Saudi Arabia
T: (+966) 1129 30332
F: (+966) 1129 30350
M: (+966) 5007 72277`,
    time: "0.7s",
  },
  {
    heading: "MAURITIUS",
    textb1: `The Prime, Ground Floor,
NexTEracom Tower 1, Cybercity,
Ebene, Mauritius 
T: (+230) 468 1610 / 13
M: (+230) 5942 4360`,
    time: "0.9s",
  },
  {
    heading: "DOHA, QATAR",
    textb1: `360nautica, 29th Floor, Borooq Tower,
West Bay,
Doha, Qata `,
    time: "1s",
  },
];

export const JoinUsData = [
  {
    url: "/careers",
    img: "/images/join_us_img_1.jpg",
    time: "0.1s",
    time1: "0.2s",
    headingColor: "mr_dafoe tx_wht",
    heading: "Career",
    textColor: "tx_wht",
  },
  {
    url: "/fun",
    img: "/images/join_us_img_2.jpg",
    time: "0.3s",
    time1: "0.4s",
    headingColor: "mr_dafoe tx_ylw",
    heading: "Fun",
    textColor: "tx_ylw",
  },
  // {
  //   url: "#",
  //   img: "/images/join_us_img_3.jpg",
  //   time: "0.5s",
  //   time1: "0.6s",
  //   headingColor: "mr_dafoe tx_black",
  //   heading: "Travel",
  //   textColor: "tx_black",
  // },
];

export const AttendeeSayData = [
  {
    img: "images/ourEvent.mp4",
    text1: "What Attendees",
    text2: "Say about our Events",
    text3: "Know more",
  },
];
export const WhoWorkedWithUsData = [
  {
    img: "/images/who_work_with_7.jpg",
    time: "0.5s",
  },
  {
    img: "/images/who_work_with_9.jpg",
    time: "0.6s",
  },
  {
    img: "/images/who_work_with_12.jpg",
    time: "0.7s",
  },
  {
    img: "/images/who_work_with_50.jpg",
    time: "0.7s",
  },
  {
    img: "/images/who_work_with_51.jpg",
    time: "0.7s",
  },
  {
    img: "/images/who_work_with_5.jpg",
    time: "1.5s",
  },
  {
    img: "/images/who_work_with_52.png",
    time: "1.5s",
  },
  {
    img: "/images/who_work_with_3.jpg",
    time: "0.1s",
  },
  {
    img: "/images/who_work_with_53.png",
    time: "1.5s",
  },
  {
    img: "/images/who_work_with_2.jpg",
    time: "0.2s",
  },
  {
    img: "/images/who_work_with_16.jpg",
    time: "0.3s",
  },
  {
    img: "/images/who_work_with_8.jpg",
    time: "0.8s",
  },
  {
    img: "/images/who_work_with_14.jpg",
    time: "1.3s",
  },
  {
    img: "/images/who_work_with_13.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_54.png",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_55.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_56.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_57.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_58.png",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_59.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_60.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_10.jpg",
    time: "0.4s",
  },
  {
    img: "/images/who_work_with_61.jpg",
    time: "1.4s",
  },
  {
    img: "/images/who_work_with_15.jpg",
    time: "1.2s",
  },
  {
    img: "/images/who_work_with_6.jpg",
    time: "1s",
  },
  {
    img: "/images/who_work_with_11.jpg",
    time: "0.9s",
  },
  {
    img: "/images/who_work_with_1.jpg",
    time: "1.1s",
  },
  // {
  //   img: "/images/who_work_with_4.jpg",
  //   time: "1.6s",
  // },
];

export const AdvisoryBoardData = [
  {
    img: "/images/advisory-members/engr-mansour-al-obaid.png", //advisory_board_member_6.png",
    name: "Engr. Mansour Al Obaid",
    text: "Chairman – ICT Committee, Digital Health & Education, Cybersecurity & Emerging Technologies",
    country: "RIYADH CHAMBER",
    time: "1.3s",
  },
  {
    img: "/images/advisory-members/Abdul-Latif-Al-Blowi.png", //advisory_board_member_6.png",
    name: "Abdul Latif Al Blowi",
    text: "Director of Emerging Technologies, Communications, Space & Technology Commission",
    country: "",
    time: "1.3s",
  },
  {
    img: "/images/advisory-members/Laila-Al-Hadhrami.png", //advisory_board_member_6.png",
    name: "Laila Al Hadhrami",
    text: "Executive Board Member",
    country: "Oman IT Society",
    time: "1.3s",
  },
  {
    img: "/images/advisory-members/dr-satyam.png", //advisory_board_member_1.png",
    name: "DR. SATYAM PRIYADARSHY",
    text: "Technology Fellow & Chief Data Scientist, Halliburton",
    country: "UNITED STATES",
    time: "0.1s",
  },
  {
    img: "/images/advisory-members/ganesh.png", //advisory_board_member_4.png",
    name: "DR. GANESH NATARAJAN",
    text: "Founder - 5F World; Chairman SVP India; Center for AI and advanced analytics",
    country: "INDIA",
    time: "0.3s",
  },
  {
    img: "/images/advisory-members/paritosh.png", //advisory_board_member_5.png",
    name: "DR. PARITOSH BASU",
    text: "Senior Professor, School of Business Management, NMIMS University; Ex CFO, Reliance Communications",
    country: "INDIA",
    time: "0.5s",
  },
  {
    img: "/images/advisory-members/dr-sid.png", //advisory_board_member_2.png",
    name: "DR. SID AHMED BENRAOUANE",
    text: "Professor, Carlson School of Management, UMN; Advisor, Dubai Police;",
    country: "UNITED ARAB EMIRATES",
    time: "0.7s",
  },
  {
    img: "/images/advisory-members/axel.png", //advisory_board_member_3.png",
    name: "AXEL ANGELI",
    text: "Digital Transformation Evangelist; Founder, Logosworld",
    country: "GERMANY",
    time: "0.9s",
  },
  {
    img: "/images/advisory-members/jorge.png", //advisory_board_member_6.png",
    name: "JORGE SEBASTIAO",
    text: "CTO EcoSystem, Huawei",
    country: "UNITED ARAB EMIRATES",
    time: "1.1s",
  }
];

export const ConnectioningBusinessData = [
  {
    heading: "OUR EXPERTISE",
    text: "FUTURISTIC EVENTS",
    discription:
      "Some of the world’s most renowned high-impact events are crafted for Clevel executives & innovative tech companies to share knowledge, learn cutting-edge trends, tackle business challenges, bolster brand recognition, forge key relationships, and solve problems.",
  },
  {
    heading: "OUR EXPERTISE",
    text: "MANAGED B2B EVENTS",
    discription:
      "Comprehensive end-to-end service to help you manage your existing events or launch new events fully aligned with your goals & objectives. We will drive conceptualization, content creation, stakeholder acquisition, marketing, PR, commercialization, logistics & more.",
  },
  {
    heading: "OUR EXPERTISE",
    text: "BESPOKE EVENTS",
    discription:
      "Uniquely tailored engagement services to meet organizational demands such as launching new products, solidifying leadership, penetrating new markets, growing brand presence, acquiring business or investments, gaining media coverage and more.",
  },
  // {
  //   heading: "OUR EXPERTISE",
  //   text: "EDUCATION & TRAINING",
  //   discription:
  //     "Upskilling, cross-skilling, re-skilling, and validation to help you build and enhance internal capacity via half-day workshops, full day bootcamps and multiple day training with certification from leading industry bodies.",
  // },
  // {
  //   heading: "OUR EXPERTISE",
  //   text: "VIRTUAL EVENTS",
  //   discription:
  //     "The recent pandemic has caused a shift in how we live and work. Our virtual events platform seeks to provide all the benefits you would experience at a physical event but with even more to offer.",
  // },
];
export const ConnectioningBusinessImageData = [
  {
    img: "/images/futuristic-events-new.png",
  },
  {
    img: "/images/b2b-events-new.png",
  },
  {
    img: "/images/be-spoke-new.png",
  },
  // {
  //   img: "/images/education-training-min.png",
  // },
  // {
  //   img: "/images/virtual-events.png",
  // },
];

export const TresconData = [
  {
    img: "/images/DSC00659.png",
    name: "ABOUT",
    link: "/about",
    text: "An exemplary board of highly accomplished advisors, and a senior management with over eight decades of experience in the events industry is leading Trescon to create world-class exhibitions, some of the industry's biggest conferences and high-level summits - all over the world.",
  },
  {
    img: "/images/shutterstock_1698876385.png",
    name: "HISTORY",
    link: "/about",
    text: "Since its inception, Trescon has successfully connected thousands of industry experts, technology providers, startups, investors, regional policymakers, government authorities to more than 200k+ participants covering various industry sectors.",
  },
  {
    img: "/images/AFS04248.png",
    name: "OUR EXPERTISE",
    link: "#connectinglink",
    text: "With an ever-growing team of 250+ professionals in registered and partner offices across the world, Trescon delivers world-class B2B events covering emerging trends, future technologies, major industry sectors and economies - all with global standards and local know-how.",
  },
];

export const HeroImageSwipeData = [
  {
    img: "/images/wbs-hodl-logo-trescon.svg",
    weburl: "https://hodlsummit.com/",
    // data: [
    //   {
    //     title: "22",
    //     sign: "",
    //     description: "GLOBAL EDITIONS",
    //     time: ".2s",
    //   },
    //   {
    //     title: "35",
    //     sign: "k+",
    //     description: "C-LEVEL ATTENDEES",
    //     time: ".4s",
    //   },
    //   {
    //     title: '2000',
    //     sign: '+',
    //     description: 'SPEAKERS',
    //     time: '.6s'
    //   },
    //   {
    //     title: "7900",
    //     sign: "+",
    //     description: "INVESTORS",
    //     time: ".8s",
    //   },
    //   {
    //     title: "1320",
    //     sign: "+",
    //     description: "EXHIBITORS",
    //     time: "1s",
    //   },
    //   {
    //     title: "52",
    //     sign: "+",
    //     description: "COUNTRIES REPRESENTED",
    //     time: "1.2s",
    //   }
    // ]
  },
  {
    img: "/images/demandify24.png",
    weburl: "https://demandifymedia.com/",
  },
  {
    img: "/images/trescon-fondation24.png",
    weburl: "https://tresconfoundation.com/",
  },
];

export const HeroBannerImageData = {
  tresconlogo: "/images/trescon_banner_logo.png",
};

export const HeroBannerVideoData = {
  bannervideo: "/images/banner_video_wbs.webm",
};

export const FooterData = [
  {
    text: "Cookie Policy",
    link: "/cookie-policy",
  },
  {
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    text: "Code of Conduct",
    link: "/code-of-conduct",
  },
  {
    text: "General Terms & Conditions",
    link: "/general-terms-and-conditions",
  },

  
];

export const funImages = [
  {
    id: 1,
    img : "/images/fun/1.jpg"
  },
  {
    id: 2,
    img : "/images/fun/2.jpg"
  },
  {
    id: 3,
    img : "/images/fun/3.jpg"
  },
  {
    id: 4,
    img : "/images/fun/4.jpg"
  },
  {
    id: 5,
    img : "/images/fun/5.jpg"
  },
  {
    id: 6,
    img : "/images/fun/6.jpg"
  },
  {
    id: 7,
    img : "/images/fun/7.jpg"
  },
  {
    id: 8,
    img : "/images/fun/8.jpg"
  },
  {
    id: 9,
    img : "/images/fun/9.jpg"
  },
  {
    id: 10,
    img : "/images/fun/10.jpg"
  },
  {
    id: 11,
    img : "/images/fun/11.jpg"
  },
  {
    id: 12,
    img : "/images/fun/12.jpg"
  },
  {
    id: 13,
    img : "/images/fun/13.jpg"
  },
  {
    id: 14,
    img : "/images/fun/14.jpg"
  },
  {
    id: 15,
    img : "/images/fun/15.jpg"
  },
  {
    id: 16,
    img : "/images/fun/16.jpg"
  },
  {
    id: 17,
    img : "/images/fun/17.jpg"
  },
  {
    id: 18,
    img : "/images/fun/18.jpg"
  },
  {
    id: 19,
    img : "/images/fun/19.jpg"
  },
  {
    id: 20,
    img : "/images/fun/20.jpg"
  },
  {
    id: 21,
    img : "/images/fun/21.jpg"
  },
  {
    id: 22,
    img : "/images/fun/22.jpg"
  },
  {
    id: 23,
    img : "/images/fun/23.jpg"
  },
  {
    id: 24,
    img : "/images/fun/24.jpg"
  },
  {
    id: 25,
    img : "/images/fun/25.jpg"
  },
  {
    id: 26,
    img : "/images/fun/26.jpg"
  },
  {
    id: 27,
    img : "/images/fun/27.jpg"
  },
  {
    id: 28,
    img : "/images/fun/28.jpg"
  },
  {
    id: 29,
    img : "/images/fun/29.jpg"
  },
  {
    id: 30,
    img : "/images/fun/30.jpg"
  },
  {
    id: 31,
    img : "/images/fun/31.jpg"
  },
  {
    id: 32,
    img : "/images/fun/32.jpg"
  },
  {
    id: 33,
    img : "/images/fun/33.jpg"
  },
  {
    id: 34,
    img : "/images/fun/34.jpg"
  },
  {
    id: 35,
    img : "/images/fun/35.jpg"
  },
  {
    id: 36,
    img : "/images/fun/36.jpg"
  },
  {
    id: 37,
    img : "/images/fun/37.jpg"
  },
  {
    id: 38,
    img : "/images/fun/38.jpg"
  },
  {
    id: 39,
    img : "/images/fun/39.jpg"
  },
  {
    id: 40,
    img : "/images/fun/40.jpg"
  },
  {
    id: 41,
    img : "/images/fun/41.jpg"
  },
  {
    id: 42,
    img : "/images/fun/42.jpg"
  },
  {
    id: 43,
    img : "/images/fun/43.jpg"
  },
  {
    id: 44,
    img : "/images/fun/44.jpg"
  },
  {
    id: 45,
    img : "/images/fun/45.jpg"
  },
  {
    id: 46,
    img : "/images/fun/46.jpg"
  },
  {
    id: 47,
    img : "/images/fun/47.jpg"
  }
]

export const carouselData = [
  {
    img : "/images/about/carousel-img/h-cash.jpg",
    content : "We met the industry leaders and blockchain technology experts – all in one place. Very promising event!"
  },
  {
    img : "/images/about/carousel-img/ethercash.jpg",
    content : "It is a perfect platform. WBS met all our expectations. We are just excited to be here!"
  },
  {
    img : "/images/about/carousel-img/darico.jpg",
    content : "It was a great launchpad for us to tell the whole world about our upcoming projects and its benefits."
  },
  {
    img : "/images/about/carousel-img/tufin.jpg",
    content : "Majority of the attendees were decision makers and key influencers. We are confident of doing business very soon."
  },
  {
    img : "/images/about/carousel-img/master-card.jpg",
    content : "Let us come together & collaborate along with event leaders like Trescon, to embark the exciting digital transformation journey."
  },
  {
    img : "/images/about/carousel-img/zendesk.jpg",
    content : "The parallel conversations during luncheon & networking has helped us in cutting-down our sales cycle by months."
  },
  {
    img : "/images/about/carousel-img/thalse.jpg",
    content : "We loved all the sessions and are very happy to be a part of this event. Great team & fantastic show."
  },
  {
    img : "/images/about/carousel-img/american-blockchian-council.jpg",
    content : "I couldn’t help but feel impressed with the number of senior experts and delegates who came from all over the world"
  }

]

