import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import './about.css'
import {carouselData} from '../../data.js';
// import "../swiper.min.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, {
    Navigation,
    Scrollbar,
    Autoplay,
    Pagination,
  } from "swiper";
  SwiperCore.use([Navigation, Scrollbar, Autoplay, Pagination]);

// import OwlCarousel from 'react-owl-carousel'
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

function About() {

  return (
    <div className="About-Page">
        <Container fluid >
            <Image src={process.env.PUBLIC_URL + "/images/about/banner-about.jpg"} alt="About Page Banner"/>
        </Container>
        <div className='Content-Sec'>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2>
                            <span className='Main-Heading'>ABOUT </span>
                            <span className='Sub-Heading'> TRESCON</span>
                        </h2>
                    </Col>
                </Row>

                <Row>
                    <Col md={7}>
                        <p className='Content'>Trescon is a global business events and consulting firm that provides a wide range of business services to a diversified client base.</p>
                        <p className='Content'>Trescon is founded and managed by a group of specialists with more than 8 decades of combined expertise in successfully developing business events, trainings and consulting for corporates, governments, associations and high-net-worth individuals across the world.</p>
                        <p className='Content'>Our advisory board members comprise of an ever-growing exceptional mix of senior-level industry veterans and successful tech entrepreneurs.</p>
                        <p className='Content'>With a deep understanding of the realities and requirements of the growth markets we operate in – we strive to deliver innovative and high quality business platforms for our clients.</p>
                        <p className='Content'>We produce highly focused B2B events that connect businesses with opportunities through conferences, road shows, expos, demand generation and consulting services.</p>
                        <p className='Content'>Our mission is to add value to our clients’ sales and marketing engagements, business development activities, and learning & development programmes.</p>
                        <p className='Content'>Trescon’s main business platforms are C-suite events, B2B bespoke events, Intelligent demand generation, Expos, and Investor connect.</p>
                        <p className='Content'>Get in touch with us on <a href="mailto:enquiry@tresconglobal.com" >enquiry@tresconglobal.com</a></p>

                    </Col>
                    <Col md={5}>
                        <Image src={process.env.PUBLIC_URL + "/images/about/about-right.jpg"} fluid />
                    </Col>
                </Row>
                </Container>
                <div className='testimonials'>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h2 className='Main-Heading'>TESTIMONIALS</h2>
                            </Col>
                        </Row>
                        <Swiper
                            breakpoints={{
                              0:{
                                slidesPerView:1
                              },
                              768:{
                                slidesPerView:1
                              },
                              1000:{
                                slidesPerView:3
                              }
                            }}
                            
                            spaceBetween={10}
                            loop={true}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                              pauseOnMouseEnter: true,
                            }}
                            navigation={{
                              nextEl: ".swiper-button-next",
                              prevEl: ".swiper-button-prev",
                            }}
                            className="swiper-wrapper"
                        >
                            {carouselData?.map((res, index) => {
                                return (
                                  <div className="swiper-slide">
                                    <SwiperSlide>
                                      <div className="">
                                        <Image
                                          className="wow fadeInUp"
                                          src={res.img}
                                          
                                        />
                                        <p className='content'>{res.content}</p>
                                      </div>
                                    </SwiperSlide>
                                  </div>
                                );
                              })}
                              <div className="testimonials-btn">
                                <div className="swiper-button-next">
                                </div>
                                <div className="swiper-button-prev">
                              </div>
                          </div>
                        </Swiper>
                          
                    </Container>
                </div>
            
        </div>
    </div>
  )
}

export default About