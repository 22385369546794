import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';


function Header() {
    return (

        <header className="header_main">
            <div className="container">
                <div className="header_inr d-flex justify-content-between align-items-center">
                    <div className="header_left">
                        <Link to="/">
                            <img src="/images/header_logo.png" alt="" />
                        </Link>
                    </div>
                    <div className="mobileIcon">
                        <Link to="/"></Link>
                        {/* <a href=""></a> */}
                    </div>
                    <div className="collapse navbar-collapse header_right" id="navbarNav">
                        <div className="menu">
                            <ul className="d-flex justify-content-between align-items-center">
                                <li><a href="#Homelink">HOME</a></li>
                                <li><a href="#aboutlink">ABOUT</a></li>
                                {/* <li><a href="#connectinglink">OUR PLATFORMS </a></li> */}
                                <li className="nav-item dropdown" id="navbarNavDarkDropdown">
                                    <div className="nav-link nav-div p-0 dropdown-toggle" href="#" id="navbarDarkDropdownMenuLinkPlatforms" role="button" data-bs-toggle="dropdown" >
                                        OUR EXPERTISE
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLinkPlatforms">
                                        <li>
                                            <a className="dropdown-item" href="https://www.tresconglobal.com/conferences/" rel="noreferrer"
                                                target="_blank">
                                                CONFERENCES
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://www.tresconglobal.com/bespoke-events/" rel="noreferrer"
                                                target="_blank">
                                                BESPOKE EVENTS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://www.tresconglobal.com/expos" rel="noreferrer"
                                            target="_blank">
                                                EXPOS
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown" id="navbarNavDarkDropdown">
                                    <div className="nav-link nav-div p-0 dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" >
                                        Upcoming Events
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                                        
                                        
                                        {/* 
                                        <li>
                                            <a className="dropdown-item" href="https://worldcxsummit.com/india/" rel="noreferrer"
                                            target="_blank">
                                                WORLD CX SUMMIT INDIA
                                            </a>
                                        </li>
                                        
                                        <li>
                                            <a className="dropdown-item" href="https://worldaishow.com/india/" rel="noreferrer"
                                            target="_blank">
                                                WORLD AI SHOW INDIA
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#" rel="noreferrer">
                                                DUBAI FUTURE FORUM UAE
                                            </a>
                                        </li> */}
                                        <li>
                                            <a className="dropdown-item" href="https://futuresustainabilityforum.com/" rel="noreferrer" target="_blank">
                                                FUTURE SUSTAINABILITY FORUM UAE
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://bigcioshow.com/" rel="noreferrer"
                                                target="_blank">
                                                BIG CIO SHOW INDIA
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://hodlsummit.com/dubai2025/" rel="noreferrer"
                                                target="_blank">
                                                HODL (formerly WBS) UAE
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://dubaiaiweb3festival.com/" rel="noreferrer"
                                            target="_blank">
                                                DUBAI AI & WEB3 FESTIVAL (DIFC) UAE
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://dubaifintechsummit.com/" rel="noreferrer"
                                                target="_blank">
                                                DUBAI FINTECH SUMMIT (DIFC) UAE
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://datewithtech.com/jakarta/ai/" rel="noreferrer"
                                                target="_blank">
                                                DATE AI SHOW INDONESIA
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://datewithtech.com/jakarta/fintech/" rel="noreferrer"
                                                target="_blank">
                                                DATE FINTECH SHOW INDONESIA
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </li>
                                {/* <li className="nav-item dropdown" id="navbarNavDarkDropdown">
                                    <div className="nav-link nav-div p-0 dropdown-toggle" href="#" id="navbarDarkDropdownMenuLinkSchedule" role="button" data-bs-toggle="dropdown" >
                                        FORWARD SCHEDULE
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLinkSchedule">
                                        <li>
                                            <a className="dropdown-item" href="https://trescon.s3.us-east-2.amazonaws.com/corp/event-calendar/mena-event-calendar.pdf" rel="noreferrer"
                                                target="_blank">
                                                MENA
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://trescon.s3.us-east-2.amazonaws.com/corp/event-calendar/apac-event-calendar.pdf" rel="noreferrer"
                                                target="_blank">
                                                APAC
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://trescon.s3.us-east-2.amazonaws.com/corp/event-calendar/india-event-calendar.pdf" rel="noreferrer"
                                            target="_blank">
                                                India
                                            </a>
                                        </li>
                                    </ul>
                                </li> */}
                                <li><a href="https://trescon.s3.us-east-2.amazonaws.com/corp/event-calendar/2025-forward-schedule.pdf" target="_blank">FORWARD SCHEDULE</a></li>
                                <li><a href="#clientlink">CLIENTS</a></li>
                                <li><a href="#joinuslink">CAREERS</a></li>
                                <li><a href="#contactlink">CONTACT</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;