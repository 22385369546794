import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import './PageCSS.css'

function GeneralTemsCond() {
  return (
    <div className='Terms-Condition'>
        <Container>
            <Row>
                <Col md={12} className="Main-Heading">GENERAL TERMS & CONDITIONS</Col>
            </Row>

            <Row>
                <Col md={12} className="Content-Heading">INTRODUCTION</Col>
                <p className='Content'>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person visiting this website and is compliant to Trescon’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing laws of India. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">ALTERATION OF THE ADVERTISED PACKAGE</Col>
                <p className='Content'>Trescon shall use its best endeavours to adhere to the advertised package and agenda for the event disclosed to the Client prior to the date of this agreement, but such package and/or agenda may be altered, or parts thereof omitted or dates thereof changed, for any cause that Trescon (after consultation with the Client), acting reasonably shall consider to be necessary. Notwithstanding the above, Trescon will use its best efforts to organize the event on a suitable venue or online platform (whichever is applicable), and on the proposed dates. Do not rely on any oral representations with respect to the dates. Any change in dates will be communicated by email to the client failing which, the Client is entitled for a full refund of the amount paid. Trescon is entitled, without penalty, to change the venue or online platform (whichever is applicable) or date of the event.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">CANCELLATION BY CLIENT</Col>
                <ul className='Content'>
                    <li>
                        Any cancellation request that is received within 48 hours of booking will be entitled to receive a credit note equivalent to the full booking amount - only if the booking date is not less than 30 days from the event date.
                    </li>
                    <li>
                        Any cancellation request raised after 48 hours of booking will be entitled to receive a credit note equivalent to 50% of the total booking amount - only if the booking date is not less than 30 days from the event date.
                    </li>
                    <li>
                        Any cancellation request received within the 30 days from the event date will not be entitled for any credit note.
                    </li>
                    <li>
                        If the client does not officially request for a cancellation and does not participate in the event, there shall be no credit note issued to the client.
                    </li>
                    <li>
                        In all the above scenarios, in case if the client has not yet made the payment, 100% of the invoiced amount shall remain due.
                    </li>
                </ul>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">LIMITATION OF LIABILITY</Col>
                <p className='Content'>Trescon shall not be liable for any special, consequential, or incidental damages arising out of this agreement or the services provided hereunder, including but not limited to damages for lost profits, loss of use or any damages or sums paid by the Client to third parties, even if Trescon has been advised of the possibility of such damages.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">PRIVACY AND DATA PROTECTION</Col>
                <p className='Content'>Trescon is granted the right to use, for promotional purposes, any material recorded at the Event, whether in photographic, audio or audio-visual format, without time limitation and in any format. The Attendee hereby gives up any privacy right he might have due to his presence at the Event and accepts not to enter into any legal proceedings against Trescon arising from the Organizer’s use of such material. The data filled in this form will be stored and used for obvious logistic and communication purposes. Trescon will not sell or disclose them to any third party other than that for matters directly related to the Event.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">POSTPONEMENT OF EVENT</Col>
                <p className='Content'>If Trescon decides to postpone the event for reasons other than Force Majeure then an alternate date for the event will be scheduled within six months from the original date and client's participation will be moved to the rescheduled event. In case the client is not able to attend the rescheduled event, a full credit note will be given which can be used by the client to attend any future Trescon events. In case the rescheduled event takes place after six months from the original date of the event then client can demand for a full refund. Such refund will be processed within 30 days of receiving the request.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">CANCELLATION OF EVENT</Col>
                <p className='Content'>If Trescon cancels the event for any reason then the full amount paid by the client will be refunded within 30 business days of cancellation without client being able to claim any compensation for the same.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">MISCELLANEOUS</Col>
                <p className='Content'>This agreement constitutes the sole and exclusive agreement between the Trescon and the Client. The Client acknowledges that no other warranties, representations or acknowledgements, written or verbal, have been made that are not reflected herein. These terms and conditions may not be changed by Trescon without the written confirmation of a director thereof, and, in any event, no such change shall be effective unless made in a writing signed by Trescon and an authorized representative of the Client. The names of all authorized directors of Trescon will be provided upon request. Time is of the essence in relation to this agreement. This agreement shall be binding upon all the parties upon execution and delivery to the other party of this contract. Delivery by email shall constitute delivery hereof. Notices or communications in connection with this agreement shall be sent via email and shall be sent to the Parties (Trescon and the Client) as follows: Notices sent via email shall be deemed to be received at the time of delivery. All notices sent on a day other than a business day shall be deemed to be received on the immediately following business day. The construction, validity and performance of this agreement shall be governed in all respects by the laws of Bengaluru, India to the exclusive jurisdiction of the court in Bengaluru, India.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">GENERAL TERMS & CONDITIONS FOR CLIENTS <br /><span className="Content-SubHeading">SPONSORS/PARTNERS/EXHIBITORS</span></Col>
                <p className='Content'>If the Client cancels the agreement more than 7 days after its signature and more than two months before the Event, he shall be required to pay to Trescon 50% of the total amount mentioned in this booking form. If the cancellation is between 30 to 60 days before the event, then 75% of the contract amount remains due. In case of a cancellation less than a month before the Event, 100% of the price would remain due. That amount covers the marketing work already performed by Trescon for the Client as well as the loss of other opportunities incurred by Trescon due to the client initial engagement. It shall in no circumstances be negotiated or reduced no matter when, within the defined timeframe, intervenes the cancellation. Trescon can offer the client a full Credit up to the amount actually paid by the client to Trescon. This Credit can be used within one year from the issue date for any event hosted by Trescon. There shall be no refunds or credits for Cancellation received less than one month prior to the event. If the client does not officially cancel this agreement and does not participate in the event the invoice will remain payable in full.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">LICENSE</Col>
                <p className='Content'>Unless otherwise stated, Trescon and/or its licensors own the intellectual property rights for all material on Trescon. All intellectual property rights are reserved. You may access this from Trescon for your own personal use subjected to restrictions set in these terms and conditions.</p>
                <p className='Content'>You must not:</p>
                <ul className="Content">
                    <li>
                        Republish material from Trescon
                    </li>
                    <li>
                        Sell, rent or sub-license material from Trescon
                    </li>
                    <li>
                        Reproduce, duplicate or copy material from Trescon
                    </li>
                    <li>
                        Redistribute content from Trescon
                    </li>
                </ul>
                <p className='Content'>Any such information, content or material that belongs to Trescon, which we have reason to believe will discredit the organization or is defamatory or breaches the intellectual property rights as set out per the above will be a violation of the general terms & conditions. Such information will be required to be taken down with immediate effect.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">HYPERLINKING</Col>
                <p className='Content'>The following organizations may link to our Website without prior written approval:</p>
                <ul className='Content'>
                    <li>
                        Government agencies;
                    </li>
                    <li>
                        Search engines;
                    </li>
                    <li>
                        News organizations;
                    </li>
                    <li>
                        Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses;
                    </li>
                    <li>
                    System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                    </li>   
                </ul>
                <p className='Content'>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>
                <p className='Content'>We may consider and approve other link requests from the following types of organizations:</p>
                <ul className='Content'>
                    <li>Commonly-known consumer and/or business information sources;</li>
                    <li>dot.com community sites;</li>
                    <li>Associations or other groups representing charities;</li>
                    <li>Online directory distributors;</li>
                    <li>Internet portals;</li>
                    <li>Accounting, law and consulting firms; and</li>
                    <li>Educational institutions and trade associations.</li>
                </ul>
                <p className='Content'>
                We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Trescon; and (d) the link is in the context of general resource information.
                </p>
                <p className='Content'>
                These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
                </p>
                <p className='Content'>
                If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Trescon. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link.
                </p>
                <p className='Content'>
                Approved organizations may hyperlink to our Website as follows:
                </p>
                <ul className='Content'>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                </ul>
                <p className='Content'>
                No use of Trescon's logo or other artwork will be allowed for linking absent a trademark license agreement.
                </p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">ANTI-HARASSMENT POLICY</Col>
                <p className='Content'>Trescon is committed to organizing events free of any form of unlawful harassment or discrimination based on gender, race, sexual orientation, religion, creed, disability, physical appearance or age.</p>
                <p className='Content'>Trescon does not tolerate the harassment of participants at the event as we are committed to maintaining a respectful and inclusive work environment. All attendees are expected to conduct themselves in a professional and appropriate manner. Attendees violating these rules will be warned or expelled from the conference, without a refund, at the discretion of the conference organisers.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">CONTENT LIABILITY</Col>
                <p className='Content'>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">REMOVAL OF LINKS FROM OUR WEBSITE</Col>
                <p className='Content'>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">DISCLAIMER</Col>
                <p className='Content'>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                <ul className='Content'>
                    <li>Limit or exclude our or your liability for death or personal injury;</li>
                    <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>Limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>Exclude any of our or your liabilities that may not be excluded under applicable law.   </li>
                </ul>
                <p className='Content'>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
                <p className='Content'>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">MESSAGES THROUGH EMAIL OR OTHER COMMUNICATIONS</Col>
                <p className='Content'>Trescon will not be held responsible for any information or message that is posted or sent to us. The user at all times should be aware of the lawfulness of such information. Trescon has no obligation to make ourselves aware of such unlawful information.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">LIMITATION OF LIABILITY</Col>
                <p className='Content'>Trescon shall not be liable for any special, consequential, or incidental damages arising out of this agreement or the services provided hereunder, including but not limited to damages for lost profits, loss of use or any damages or sums paid by the Client to third parties, even Trescon has been advised of the possibility of such damages.</p>
                <p className='Content'>Trescon has registered and partner offices across the world and conducts business in several countries where its offices are located. Hence, the terms and conditions are subject to the jurisdiction and law of the land, which will be duly mentioned in the respective contracts/booking forms/agreements and other legally binding documents.</p>
                <p className='Content'>The construction, validity and performance of these documents shall be governed in all respects by the local laws as applicable.</p>
                <p className='Content'>Should you have any queries regarding the General Terms & Conditions, feel free to contact us at: <a href="mailto:privacy@tresconglobal.com?subject=Queries regarding the General Terms & Conditions&body=">privacy@tresconglobal.com</a></p>
            </Row>
        </Container>
    </div>
  )
}

export default GeneralTemsCond