import React from 'react'
import {Link} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import './PageCSS.css'

function CookiePolicy() {
  return (
    <div className="Cookie-Policy">
        <Container>
            <Row>
                <Col md={12} className="Main-Heading">COOKIE POLICY</Col>
            </Row>

            <Row>
                <p className='Content'>We employ the use of cookies. By accessing Trescon or Trescon operated event websites, you have agreed to allow us to use cookies in agreement with Trescon's Privacy Policy.</p>
                <p className='Content'>Cookies are packets of data that contain information about a user and this information is stored by the web browser. Most interactive websites use cookies to retrieve the user’s details for each visit. Cookies are not harmful to user’s devices or files, they are used by Trescon’s services to enable the functionality of certain areas to make it easier for people visiting our website and to give you the best-personalized experience.</p>
                <p className='Content'>Trescon respects the privacy of users who browse our website. In light of this, we are committed to protecting the personal and business information of our users.</p>
                <p className='Content'>At any point you may choose to set the option on your web browser to refuse cookies, or to alert when cookies are being sent.</p>
                <p className='Content'>Please visit our <Link to="/privacy-policy">Privacy Policy</Link> page for further information.</p>
                <p className='Content'>Should you have any queries regarding this Cookie Policy, feel free to contact us at: <a href="mailto:privacy@tresconglobal.com?subject=Queries regarding Cookie Policy&body=">privacy@tresconglobal.com</a></p>
            </Row>
        </Container>
    </div>
  )
}

export default CookiePolicy