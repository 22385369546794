import React from 'react'
import { useState } from 'react';
import {Container, Image, Row, Col, Button, Modal} from 'react-bootstrap'
import HubspotForm from "react-hubspot-form"
import '../career/career.css'

function Career() {
    const initialValues = {
        'businessAnalyst' : false,
        'graphiDesigner' : false,
        'marketingManager' :false,
        'uiDesigner' : false,
        'marketingCommunication' :false
    }
    const [showDescription, setDescription] = useState(initialValues);
    const [showModal, setShowModal] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const modalHandleShow = () => {setShowModal(true)}
    const modalHandleHide = () => {setShowModal(false)}

    const handleClick = (e) => {
        const jobTitle = e.target.dataset.user;
        if(jobTitle){
            setDescription(currentObj => {
                return {
                ...currentObj, [jobTitle] : !currentObj[jobTitle]}
            })
            setShowContent(!showContent);
        }
    }
  return (
    <div className='career_page'>
        <Container fluid>
            <Image src={process.env.PUBLIC_URL + 'images/career/banner-expo.jpg'} fluid />
        </Container>

        <Container>
            <div className='Second-Sec'>
                <Row>
                    <Col md={12}>
                        <h2 className='Heading'>CAREERS</h2>
                        <p className='Sub-Content'>We attract people who develop the best tech events around the globe. And we’re just getting started. See what we are working on now. Are you the one who falls into the category of “Do what you love. Love what you do”? Then take a tour and discover our most popular career paths.</p>
                    </Col>
                </Row>

                <div className='Desktop'>
                    <Row className="align-content-center">
                        <Col md={6} className="">
                            <h3 className='Sub-Heading'>Create something the world has never seen</h3>
                            <p className='Sub-Content'>From 20-people in 2016 to 250+ now, we have made sure we work with the people who can truly shape this industry and can raise the bar even higher for our competitors. In order to do so, we have the best people working with us in our registered and partner offices across the globe.</p>
                        </Col>
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-about.png'} fluid className="d-block mx-auto" />
                        </Col>
                    </Row>
                    <Row>
                        <div className="appear rtl" style={{opacity: '1'}}>
                        <div></div>
                        <div></div>
                        </div>
                    </Row>
                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-govt.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6}>
                            <h3 className='Sub-Heading'>Work with best enterprises and Governments from across the world</h3>
                            <p className='Sub-Content'>In order to give best content to our audience, we work with the brightest minds, thought leaders, academicians, some of the most successful entrepreneurs, and government policy makers. For you, it is once-in-a-lifetime opportunity to work with them and chase your dreams.</p>
                        </Col>
                    </Row>
                    <Row>
                        <div className="appear ltr" style={{opacity: '1'}}>
                            <div></div>
                            <div></div>
                        </div>
                    </Row>

                    <Row className="align-content-center">
                        <Col md={6} className="">
                            <h3 className='Sub-Heading'>Health benefits - Because it is all about you!</h3>
                            <p className='Sub-Content'>Employee health and wellness has been our top priority which can be noticed from the health benefits we offer while you join Trescon.</p>
                        </Col>
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-health.png'} fluid className="d-block mx-auto" />
                        </Col>
                    </Row>
                    <Row>
                        <div className="appear rtl" style={{opacity: '1'}}>
                        <div></div>
                        <div></div>
                        </div>
                    </Row>
                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-world.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6}>
                            <h3 className='Sub-Heading'>Travel the whole world!</h3>
                            <p className='Sub-Content'>We organise conferences, summits and exhibitions all over the world and you have an opportunity to travel with the team. We are sure a good business-cum-vacation trip will only add pretty pictures to your Instagram feed and wonderful experiences to cherish for years!</p>
                        </Col>
                    </Row>
                    <Row>
                        <div className="appear ltr" style={{opacity: '1'}}>
                            <div></div>
                            <div></div>
                        </div>
                    </Row>
                    <Row className="align-content-center">
                        <Col md={6} className="">
                            <h3 className='Sub-Heading'>We’re looking for…</h3>
                            <p className='additional-content'>Bangalore office</p>
                            <p className='Sub-Content'>Business Analyst - Sponsorship Sales</p>
                            <p className='additional-content'>Manipal office</p>
                            <p className='Sub-Content'>Business Analyst - Sponsorship Sales</p>
                            <p className='Sub-Content'>Business Analyst - Delegate Sales</p>
                            <p className='Sub-Content'>Client Relation executives</p>

                        </Col>
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-candidate.png'} fluid className="d-block mx-auto" />
                        </Col>
                    </Row>
                </div>

                <div className='Mobile'>
                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-about.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6} className="">
                            <h3 className='Sub-Heading'>Create something the world has never seen</h3>
                            <p className='Sub-Content'>From 20-people in 2016 to 250+ now, we have made sure we work with the people who can truly shape this industry and can raise the bar even higher for our competitors. In order to do so, we have the best people working with us in our registered and partner offices across the globe.</p>
                        </Col>
                    </Row>
                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-govt.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6}>
                            <h3 className='Sub-Heading'>Work with best enterprises and Governments from across the world</h3>
                            <p className='Sub-Content'>In order to give best content to our audience, we work with the brightest minds, thought leaders, academicians, some of the most successful entrepreneurs, and government policy makers. For you, it is once-in-a-lifetime opportunity to work with them and chase your dreams.</p>
                        </Col>
                    </Row>

                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-health.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6} className="">
                            <h3 className='Sub-Heading'>Health benefits - Because it is all about you!</h3>
                            <p className='Sub-Content'>Employee health and wellness has been our top priority which can be noticed from the health benefits we offer while you join Trescon.</p>
                        </Col>
                    </Row>
                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-world.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6}>
                            <h3 className='Sub-Heading'>Travel the whole world!</h3>
                            <p className='Sub-Content'>We organise conferences, summits and exhibitions all over the world and you have an opportunity to travel with the team. We are sure a good business-cum-vacation trip will only add pretty pictures to your Instagram feed and wonderful experiences to cherish for years!</p>
                        </Col>
                    </Row>
                    <Row className="align-content-center">
                        <Col md={6}>
                            <Image src={process.env.PUBLIC_URL+'images/career/icn-candidate.png'} fluid className="d-block mx-auto" />
                        </Col>
                        <Col md={6} className="">
                            <h3 className='Sub-Heading'>We’re looking for…</h3>
                            <p className='additional-content'>Bangalore office</p>
                            <p className='Sub-Content'>Business Analyst - Sponsorship Sales</p>
                            <p className='additional-content'>Manipal office</p>
                            <p className='Sub-Content'>Business Analyst - Sponsorship Sales<br />Business Analyst - Delegate Sales<br />Client Relation executives</p>
                        </Col>
                        
                    </Row>
                </div>
            </div>
        </Container>

        <Container fluid>
            <div className='Join-Trescon'>
                <Container>
                    <h2 className='Content'>Join trescon to create world-class exhibitions, some the industry's biggest conferences and high-level summits - all over the world.</h2>
                </Container>
            </div>
        </Container>

        <Container fluid>
            <div className='Join-US'>
                <Container>
                    <h2 className='Content'>JOIN US AS</h2>
                </Container>
            </div>
        </Container>
        
        <div className='Register-Box'>
            <Container>
                <Row className="justify-content-center">
                    <Col md={7}>
                        <div className='outer-box'>
                            <ul>
                                <li>
                                    <h3 className='Job-Title' data-user="businessAnalyst" onClick={handleClick}>Business Analyst – Sponsorship Sales
                                        {!showDescription.businessAnalyst ? <span>+</span>  : <span>-</span>}  
                                    </h3>
                                    {showDescription.businessAnalyst && 
                                        <>
                                            <p className="description">As a Business Analyst – Sponsorship Sales you will be responsible for building new client relationships with C-level executives from international/Domestic companies seeking to expand their business further across the globe. Your responsibilities include understanding the product, researching leads, developing business over the phone, travelling to events and running one-to-one meetings.</p>
                                            <Button type="md" className="Applay-Btn" onClick = {modalHandleShow}>Applay Now</Button>
                                        </>
                                    }
                                </li>
                                <li>
                                    <h3 className='Job-Title' data-user="graphicDesigner" onClick={handleClick}>Graphic Designer
                                        {!showDescription.graphicDesigner ? <span>+</span>  : <span>-</span>}
                                    </h3>
                                    {showDescription.graphicDesigner && 
                                        <>
                                            <p className="description">We are looking for a creative Graphic designer with up-to-date knowledge to interpret our needs & our clients’ needs and to design solutions with high visual impact. You will work on a variety of products, including websites, event flyers, brochures & all other related marketing and branding materials, corporate identity etc. and you will cooperate with our branding and marketing team.</p>
                                            <Button type="md" className="Applay-Btn" onClick = {modalHandleShow}>Applay Now</Button>
                                        </>
                                    }
                                </li>
                                <li>
                                    <h3 className='Job-Title' data-user="marketingManager" onClick={handleClick}>Online Marketing Manager
                                        {!showDescription.marketingManager ? <span>+</span>  : <span>-</span>}
                                    </h3>
                                    {showDescription.marketingManager && 
                                        <>
                                            <p className="description">Online Marking professionals are required to combine their efforts with other areas such as technical support, creative services, content writing and sales in order to plan and execute marketing campaigns.</p>
                                            <p className="description">They are responsible for the use of marketing strategies on internet. These professionals can manage a Twitter or Facebook profile in the name of a company, publishing new content and news related to that business on a daily basis in order to obtain new clients.</p>
                                            <Button type="md" className="Applay-Btn" onClick = {modalHandleShow}>Applay Now</Button>
                                        </>
                                    }
                                </li>
                                <li>
                                    <h3 className='Job-Title' data-user="uiDesigner" onClick={handleClick}>UX/UI Designer
                                        {!showDescription.uiDesigner ? <span>+</span>  : <span>-</span>}
                                    </h3>
                                    {showDescription.uiDesigner && 
                                        <>
                                            <p className="description">We are looking for a UI/UX Designer to turn our software into easy-to-use products for our clients. UI/UX Designer responsibilities include gathering user requirements, designing graphic elements. To be successful in this role, you should have experience with design software and wireframe tools. If you also have a portfolio of professional design projects that includes work with web/mobile applications, we’d like to meet you.</p>
                                            <p className="description">Ultimately, you’ll create both functional and appealing features that address our clients’ needs and help us grow our customer base.</p>
                                            <Button type="md" className="Applay-Btn" onClick = {modalHandleShow}>Applay Now</Button>
                                        </>
                                    }
                                </li>
                                <li>
                                    <h3 className='Job-Title' data-user="marketingCommunication" onClick={handleClick}>Marketing & Communication
                                        {!showDescription.marketingCommunication ? <span>+</span>  : <span>-</span>}
                                    </h3>
                                    {showDescription.marketingCommunication && 
                                        <>
                                            <p className="description">Identifying and understanding key market segments relevant to the assigned project. Producing a comprehensive marketing plan and strategy clearly defining the timeline, including requisite resource utilization.</p>
                                            <p className="description">Ability to research and segregate each market for intrinsic evaluation. Forge strategic partnerships with corporations, media houses and desirable market executives to deliver operational variables.</p>
                                            <p className="description">Coordinating and working closely with all departments to achieve project success. Detailed response analysis for on-time delivery of content variables.</p>
                                            <p className="description">Writing commercially attractive ‘copy’ to generate relevant leads and improve brand awareness for the company and its parent concerns.</p>
                                            <p className="description">Managing project deliverables in a timely fashion, while communicating internally and constantly with the team to avoid all types of internal stop-blocks.</p>
                                            <Button type="md" className="Applay-Btn" onClick = {modalHandleShow}>Applay Now</Button>
                                        </>
                                    }
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

        <Modal show={showModal}
            backdrop = "static"
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => modalHandleHide()} 
            className='form_modal'
        >
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <HubspotForm
                                portalId = "2953901"
                                formId = "8363e3b8-7d05-4e11-b280-6c7c62ddbdf6"
                                css=''
                            />
                        </Col>
                    </Row>
                </Modal.Body>
        </Modal>
    </div>

    
  )
}

export default Career