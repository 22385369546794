import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HubspotForm from "react-hubspot-form"
import "../css/Hubspotform.css"
import "../MediaPartner/MediaPartnerRegistration.css"

function MediaPartnerRegistration() {
    useEffect(()=> {
        window.scroll(0, 0)
    }, [])
    return (
        <div className='Media_Partner_Reg'>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2 className='heading text-center'>MEDIA PARTNER ENQUIRY</h2>
                        <p className='highlights text-center'>(Kindly ensure correct spelling, capitalization and spacing where necessary)</p>
                    </Col>
                </Row>
                <div className='form-sec'>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <HubspotForm
                                portalId='2953901'
                                formId='8c8e7f7d-fe00-4bba-adea-05caa4b10480'
                                css=''
                            />  
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default MediaPartnerRegistration