import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import './PageCSS.css'

function CodofConduct() {
  return (
    <div className = "Code-of-Conduct">
        <Container>
            <Row>
                <Col md={12} className="Main-Heading">CODE OF CONDUCT</Col>
                <p className='Content'>Please read this document carefully and comply with it to help us ensure everyone has a pleasant conference experience.</p>
            </Row>

            <Row>
                <Col md={12} className="Content-Heading">CODE OF CONDUCT FOR ALL EVENTS ORGANISED BY TRESCON INCLUDING THE VIRTUAL ONLINE EVENTS.</Col>
                <p className='Content'>This code of conduct is applies to all conferences/summits/seminars/meet-ups/workshops/business gatherings/event related social gatherings & dinners, (here after referred to as <b>‘event’ or ‘events’</b>), that are organised/hosted/co-hosted by Trescon, whether it is a physical event or online virtual event. By attending these events as a attendee/speaker/sponsor/partner/volunteer, you agree to abide by this code of conduct, and cooperate with organizers who enforce it.</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">SHORT VERSION</Col>
                <p className='Content'>“Trescon is dedicated to providing a harassment-free experience for everyone, regardless of gender, sexual orientation, disability, physical appearance, body size, race, or religion. We do not tolerate harassment of event participants in any form. Sexual language and imagery is not appropriate for any venue, including talks. Participants violating these rules may be sanctioned or expelled from the event without a refund at the discretion of the event organizers.”</p>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">LONG VERSION</Col>
                <Col md={12} className="Content-Heading">UNACCEPTABLE BEHAVIOUR</Col>
                <p className='Content'>The following behaviours are considered harassment and are unacceptable within our community:</p>
                <ul className='Content'>
                    <li>Violence, threats of violence or violent language directed against another person.</li>
                    <li>Sexist, racist, homophobic, transphobic, ableist or otherwise discriminatory jokes and language.</li>
                    <li>Posting or displaying sexually explicit or violent material.</li>
                    <li>Posting or threatening to post other people’s personally identifying information (“doxing”).</li>
                    <li>Personal insults, particularly those related to gender, sexual orientation, race, religion, or disability.</li>
                    <li>Inappropriate photography or recording.</li>
                    <li>Inappropriate physical contact; you should have someone’s consent before touching them.</li>
                    <li>Unwelcome sexual attention. This includes, sexualized comments or jokes; inappropriate touching, groping, and unwelcomed sexual advances.</li>
                    <li>Deliberate intimidation, stalking or following (online or in person).</li>
                    <li>Advocating for, or encouraging, any of the above behaviour.</li>
                    <li>Sustained disruption of community events, including talks and presentations.</li>
                    <li>No weapons of any kind, alcohol, or drugs are allowed at Trescon venues</li>
                    <ul>
                        <li>If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact a member of conference staff immediately. Conference staff can be reached out at the Registration Desk typically located at the venue entrance, or by clicking on Help Desk button, in case of an online event.</li>
                        <li>Conference staff will be happy to help participants contact hotel/venue security or local law enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the duration of the conference. We value your attendance.</li>
                        <li>We expect participants to follow these rules at conference and workshop venues and conference-related social events.</li>
                    </ul>
                </ul>
            </Row>
            <Row>
                <Col md={12} className="Content-Heading">CONTACT</Col>
                <p className='Content'>If you have any queries, please contact <a href="mailto:info@tresconglobal.com?subject=Queries regarding Code of Conduct&body=">info@tresconglobal.com.</a></p>
                <p className='Content'>Note: Portions of text are derived from the Citizen Code of Conduct, confcodeofconduct.com and Bocoup code of conduct.</p>
            </Row>
        </Container>
    </div>
  )
}

export default CodofConduct