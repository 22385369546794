import { BrowserRouter ,Routes,Route} from 'react-router-dom';
import './App.css';
import Footer from './components/footer';
import Header from './components/Header';
import Home from './components/home';
import About from './Page/About';
import Career from './Page/career';
import CodofConduct from './Page/CodofConduct';
import CookiePolicy from './Page/CookiePolicy';
import MediaPartnerRegistration from './Page/Forms/MediaPartner/MediaPartnerRegistration';
import ImageGallery from './Page/Fun';
import GeneralTemsCond from './Page/GeneralTemsCond';
import MediaPartner from './Page/MediaPartner/MediaPartner';
import PrivacyPolicy from './Page/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/general-terms-and-conditions" element={<GeneralTemsCond />} />
      <Route path="/code-of-conduct" element={<CodofConduct />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about" element={<About />} />
      <Route path="/media-partner-enquiry" element={<MediaPartnerRegistration />} />
      <Route path="/media-partner" element={<MediaPartner />} />
      <Route path="/fun" element={<ImageGallery />} />
      <Route path="/career" element={<Career />} />
    </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;
