import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

function PrivacyPolicy() {
  return (
    <div className="Privacy-Policy">
        <Container>
            <Row>
                <Col md={12} className="Main-Heading">PRIVACY POLICY</Col>
                <h2 className='Sub-Main-Heading'>Welcome to Trescon!</h2>
                <p className='Content'>Trescon is a global business events and consulting firm that provides a wide range of business services to a diversified client base. Trescon is founded and managed by a group of specialists with more than 6 decades of combined expertise in successfully developing business events, trainings and consulting for corporates, governments, associations and high-net-worth individuals across the world. Our advisory board members comprise of an ever-growing exceptional mix of senior-level industry veterans and successful tech entrepreneurs. With a deep understanding of the realities and requirements of the growth markets we operate in – we strive to deliver innovative and high-quality business platforms for our clients. We produce highly focused B2B events that connect businesses with opportunities through conferences, road shows, expos, demand generation and consulting services. Our mission is to add value to our clients’ sales and marketing engagements, business development activities, and learning & development programmes. Trescon’s main business platforms are C-suite events, B2B bespoke events, intelligent demand generation, Expos, and Investor connect. Trescon’s head office is registered at Concord Tower, 6th Floor, Dubai Media City P.O. Box 126732, Dubai.</p>
                <p className='Content' style={{textTransform : "uppercase"}}>THESE TERMS AND CONDITIONS OUTLINE THE RULES AND REGULATIONS FOR THE USE OF THE WEBSITE. PLEASE BE SURE TO READ THIS PRIVACY STATEMENT THOROUGHLY TO UNDERSTAND OUR PRIVACY POLICY BEFORE SUBMITTING ANY OF YOUR PERSONAL AND BUSINESS INFORMATION.</p>
                <p className='Content'>By accessing this website we assume you accept these terms and conditions that are stated in this privacy statement, including the collection and use of your information when you register, make a booking or enquiry. Do not continue to use Trescon if you do not agree to take all of the terms and conditions stated on this page.</p>

                <ol>
                    <li className="head-list">What Kind of Information We Collect
                        <ul className="sub-list">
                            <li>Personal Information</li>
                            <p className="Content">By accessing this website we assume you accept these terms and conditions that are stated in this privacy statement, including the collection and use of your information when you register, make a booking or enquiry. Do not continue to use Trescon if you do not agree to take all of the terms and conditions stated on this page.</p>
                            <li>Log</li>
                            <p className="Content">Our servers automatically collect and record information that the browser on your computer or mobile phone, tablet or similar device you use to access our Site (a “Device“) sends whenever you visit our Site Log, including Internet protocol (IP) addresses, browser type and language, referral and exit pages and URL’s, language information. We do not link this automatically-collected data to other information we collect about you. We also may collect information about various aspects of your use of our Site or your participation in any Activity, such as the dates and time of your login, the content you have viewed, the pages you have visited, the searches and/or registrations you may have made and any clickstream data. Log data will be stored by our servers based on the information sent from your app or browser. This information may be recorded by cookies or other tracking technologies. Please review our <a href="cookie-policy.php">cookies policy</a> page for further details.</p>
                            <li>Location </li>
                            <p className="Content">We may collect information about your location through location information accessible through your Device. You can change the privacy settings of your Device at any time, in order to turn off the functionality of sharing location information.</p>
                            <li>Analytics and Remarketing </li>
                            <p className="Content">Trescon uses Google analytics, HubSpot analytics, Full Story and other tools to help us improve our services. Upon your visit, we may also share anonymous information with third-party service providers of analytics services.</p>
                            <li>Social Media Features</li>
                            <p className="Content">Our websites also include social media features like the Twitter ‘Like button’ and the ‘Share button’ that operate on our Service. These features may collect your Internet protocol address, which page you are browsing on the Websites, and may set a cookie enabler feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Service. Your activity with regards to these features are governed by the privacy statement of the companies that provide them. </p>
                        </ul>
                    </li>
                    <li className="head-list">Usage of Your Information
                        <ul className="sub-list">
                            <li>Personal and business Information:</li>
                            <p className="Content">We may use your personal and business information for the following general purposes: </p>
                            <ul className="sub-list2">
                                <li> 
                                    <p className='Content'>Communication: We use your information to communicate with you in general, such as responding to your enquiries, to register attendees, issue tickets, sharing of information with partners you have given consent to share information with, and provide you with relevant connections and content during Trescon’s events.</p> 
                                </li>
                                <li> 
                                    <p className='Content'>Marketing: We use your information for the purpose of marketing and advertising services of Trescon’s events. To maintain better customer relationships, we measure interest and provide better requested services. To enhance user experience, we may use your information to provide targeted offers and updates about our events.</p>
                                </li>
                                <li>
                                    <p className='Content'>For our legal obligations: Trescon may use your information or share your information to fulfil our legal obligation under law or any such requirement that pertains to claims handling, debt collection and legal processes.</p>
                                </li>
                                <li>
                                    <p className='Content'>Improvement of our services: Data analysis, audits, researching and developing or improving new activities</p>
                                </li>
                            </ul>
                            <li>Non-Personal information</li>
                            <p className="Content">We may also use aggregated, non-personally identifiable data to analyse user behaviour to help improve Trescon’s services. Such non-personal information will be used to monitor the website, analyse trends and enhance visitor experience.</p>
                            <li>Data Retention</li>
                            <p className="Content">We will retain your personal information for the required duration to fulfil the purposes that are set out in this Privacy Policy and for the purpose of complying with our legal and regulatory obligations to resolve disputes or/and to enforce our terms of service.</p>
                        </ul>
                    </li>
                    <li className="head-list">Your Information is Protected
                        <p className="subhead-list">Information Sharing</p>
                        <p className="subhead-list">We will not share your personal and business information collected from your use of the Site with others, except as described in this Privacy Policy.</p>
                        <p className="Content">We may share your personal and business information under the following circumstances: </p>
                        <ul className="sub-list3">
                            <li className="Content">In the course of operating our business affiliates &amp; service providers, contractors and select vendors so that we can operate our business.</li>
                            <li className="Content">In the event of promotional activities, we will publish any short quote/statement from you or your professional or company bios, award nominations submitted by you in the registration form - that we can use in an upcoming press release announcing the event or on our social media platforms (In less than 100 words).</li>
                            <li className="Content">With your consent, we may share any presentation that you have provided with the event attendees.</li>
                            <li className="Content">For the purpose of operations and planning of our company’s strategy, we shall use information about your company budgets, delegate, sponsor and attendee booking information, affiliate forms, project briefs, or any financial information. The same shall apply to pitch submission information.</li>
                            <li className="Content">With your consent, we may share any such document, material or content that you have voluntarily made public. Since such information can be accessed by others, the use of such publicly hosted content by third parties is beyond our control. Should you want us to remove such information from the forums owned by Trescon, you could request removal by mailing us at <a href="mailto:privacy@tresconglobal.com?subject=Queries regarding Privacy Policy&amp;body=">privacy@tresconglobal.com</a></li>
                            <li className="Content">Subpoenas, court orders, or legal process, to enforce legal rights, to defend against legal claims or in such circumstances where, in our judgment, a disclosure is necessary or appropriate in accordance with applicable law.</li>
                            <li className="Content">We may share your personal information if we believe it is necessary in order to investigate, detect, prevent or otherwise take action regarding illegal activities, suspected fraud or abuse.</li>
                        </ul>
                        <p className="Content">Should you have any queries regarding this Privacy Policy, feel free to contact us at <a href="mailto:privacy@tresconglobal.com?subject=Queries regarding Privacy Policy&amp;body=">privacy@tresconglobal.com</a></p>
                    </li>
                </ol>
            </Row>
        </Container>
    </div>
  )
}

export default PrivacyPolicy