import React from 'react'
import '../MediaPartner/MediaPartner.css'
import {Container, Row, Col, Button, Image} from 'react-bootstrap'
import { Link } from 'react-router-dom'

function MediaPartner() {

  return (
    <div className="Media_Partner">
        <div className='banner-sec'>
          <Container className="mob-padding">
            <Row>
              <Col md={12}>
                <div className='Text-Box'>
                  <h1 className='Heading'>BE AT THE FOREFRONT OF EMERGING TECHNOLOGY</h1>
                  <p className="SubContent">Capture compelling stories from influential speakers and solution architects. Trescon can help you tell your story to an elite global audience.</p>
                  <Link to="/media-partner-enquiry" target="_blank">
                    <Button size="md" className="Btn">APPLY FOR MEDIA ACCREDITATION <i className='fa fa fa-angle-double-right'></i></Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="Second-Section">
          <Container>
            <Row className="Row-Gap1">
              <Col md={6} className="Text-Sec align-self-center">
                <h2 className='Heading'>EXCLUSIVE INTERVIEWS</h2>
                <p className='SubHeading'>Organise interviews, introductions, and network with the biggest names in emerging technologies.</p>
              </Col>
              <Col md={6} className="Image-Sec">
                <Image src={process.env.PUBLIC_URL+'images/Media-Partner/exclusive-interviews.jpg'} />
              </Col>
            </Row>

            <Row className="Row-Gap2">
              <Col md={6} className="Image-Sec">
                <Image src={process.env.PUBLIC_URL+'images/Media-Partner/stories.jpg'} />
              </Col>
              <Col md={6} className="Text-Sec Left-Sec align-self-center">
                <h2 className='Heading'>STORIES</h2>
                <p className='SubHeading'>Capture thought-provoking dialogues on how governments and businesses are integrating the latest emerging tech across industry verticals.</p>
              </Col>
            </Row>

            <Row className="Row-Gap3">
              <Col md={6} className="Text-Sec align-self-center">
                <h2 className='Heading'>DISCOVER SOLUTIONS</h2>
                <p className='SubHeading'>Be the first to share ground-breaking innovations and solutions that are defining the current technology landscape.</p>
              </Col>
              <Col md={6} className="Image-Sec">
                <Image src={process.env.PUBLIC_URL+'images/Media-Partner/discover-startups.jpg'} />
              </Col>
            </Row>
          </Container>
        </div>

        <div className='Third-Sec'>
            <Container>
              <Row>
                <Col md={12}>
                  <h2 className='Heading'>APPLICATION PROCESS</h2>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mar-bot">
                  <div className='Sec1'>                 
                    <Image src={process.env.PUBLIC_URL+'/images/Media-Partner/icn-register.svg'} fluid/>
                    <h2 className='Main-Heading'>REGISTER YOUR INTEREST</h2>
                    <p className='Sub-Heading'>Apply for media accreditation as a Media Partner or Journalist.</p>
                  </div>
                </Col>

                <Col md={4} className="mar-bot">
                  <div className='Sec2'>
                    <Image src={process.env.PUBLIC_URL+'/images/Media-Partner/icn-assessment.svg'} fluid/>
                    <h2 className='Main-Heading'>ASSESSMENT</h2>
                    <p className='Sub-Heading'>Our media team will evaluate your company/profile and if eligible - draft a partnership that is best suited for you.</p>
                  </div>
                </Col>

                <Col md={4} className="mar-bot">
                  <div className='Sec3'>
                    <Image src={process.env.PUBLIC_URL+'/images/Media-Partner/icn-congratulations.svg'} fluid/>
                    <h2 className='Main-Heading'>CONGRATULATIONS, YOU MADE IT!</h2>
                    <p className='Sub-Heading'>Our dedicated media representative will walk you through the onboarding process.</p>
                  </div>
                </Col>
                <Link to="/media-partner-enquiry" target="_blank" style={{textAlign:'center'}}>
                  <Button size='md' className="Applay_Btn ">APPLAY NOW <i className="fa fa fa-angle-double-right"></i></Button>
                </Link>

              </Row>
            </Container>
        </div>
    </div>
    
  )
}

export default MediaPartner