import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {funImages} from '../../data.js';
import './fun.css';

function ImageGallery() {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  
  const showImage = (image, index) => {
    setImageToShow(image);
    setCurrentIndex(index);
    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  const showNext = (e) => {
    e.stopPropagation();
    const totalLength = funImages.length;
    if(currentIndex + 1 >= totalLength){
      setCurrentIndex(0);
      const newUrl = funImages[0].img;
      console.log("newUrl in", newUrl)
      setImageToShow(newUrl);
      return;
  }
  const newIndex = currentIndex + 1;
  const newUrl = funImages.filter((item) => {
      return funImages.indexOf(item) === newIndex;
  });
  console.log("newUrl out", newUrl)
  const newItem = newUrl[0].img;
  setImageToShow(newItem)
  setCurrentIndex(newIndex);
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    const totalLength = funImages.length;
        if(currentIndex === 0){
            setCurrentIndex(totalLength - 1);
            const newUrl = funImages[totalLength - 1].img;
            setImageToShow(newUrl);
        }
        const newIndex = currentIndex - 1;
        const newUrl = funImages.filter((item) => {
            return funImages.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].img;
        setImageToShow(newItem);
        setCurrentIndex(newIndex);
  };
  

  return (

    <div className='Fun_Page'>
      <Container>
        <Row>
           {funImages && funImages.map((image, index) => {
              return (
                <>
                  <Col md={3} xs={6} className="columns">
                    <img className="image-card" key={image.id} onClick={() => showImage(image.img, index)} src={image.img} />
                  </Col>
                </>
              )
            })
          }
      {
        lightboxDisplay ? 
        <div id="lightbox" onClick={hideLightBox}>
          <button onClick={showPrev} className="previous">⭠</button>
          <img id="lightbox-img" src={imageToShow}></img>
          <button onClick={showNext} className="next">⭢</button>
        </div>
       : ""
      }
        </Row>
      </Container>
    </div>
  );
}

export default ImageGallery
